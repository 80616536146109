/* custom-carousel.css */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.carousel-control-prev-icon:before,
.carousel-control-next-icon:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.5 0l-4 4 4 4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.5 0l-2.5 2.5 2.5 2.5z'/%3E%3C/svg%3E");
}
