.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  border-radius: 5px;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow here */
}

.form-group {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
}

.input-field {
  width: 100%;
}

.error-message {
  color: red;
  font-size: 14px;
}

.submit-button {
  background-color: #5ce1e6;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
