.LoginButton {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.signInButton {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  color: rgb(255, 255, 255);
}

.enroll-btn {
  background: linear-gradient(45deg, #20a0e9, #1ea48b, #0d36bd);
}

/* .searchField {
  border-radius: 10%;
} */
